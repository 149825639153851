@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 100%;
}

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

/**
 * @see: https://benborgers.com/posts/tailwind-h-screen
 */
@supports (height: 100dvh) {
  :root {
    --viewport-height: 100dvh;
  }
  .h-screen {
    height: 100dvh;
  }
  .max-h-screen {
    max-height: 100dvh;
  }
}

@supports (-webkit-touch-callout: none) and (not (height: 100dvh)) {
  :root {
    --viewport-height: -webkit-fill-available;
  }
  .h-screen {
    height: -webkit-fill-available;
  }
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}

@supports (height: -webkit-fill-available) and (not (height: 100dvh)) {
  :root {
    --viewport-height: -webkit-fill-available;
  }
  .h-screen {
    height: -webkit-fill-available;
  }
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}
